<template>
  <v-card style="max-width: 1000px" :loading="pending">
    <v-card-item class="pr-2">
      <template #title>
        <span>{{ lookupValues?.length }} {{ $t("admin.assets.lookupValues.title") }}</span>
        <v-btn variant="text" :loading="pending" icon="refresh" @click="refresh" />
      </template>
    </v-card-item>

    <v-card-text>
      <v-table style="max-height: 500px" density="comfortable" class="bg-transparent overflow-auto" fixed-header hover>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
        </colgroup>

        <thead>
          <tr>
            <th class="text-no-wrap">{{ $t("admin.assets.lookupValues.field.parent") }}</th>
            <th class="text-no-wrap">{{ $t("description") }}</th>
            <th class="text-no-wrap">{{ $t("organization") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="lookupValue in lookupValues" :key="lookupValue._id">
            <td class="text-no-wrap">
              <nuxt-link v-if="lookupValue.parent" class="text-primary" :to="`/admin/lookup-values/${lookupValue.parent._id}`">{{
                lookupValue.parent.description
              }}</nuxt-link>
            </td>
            <td class="text-no-wrap">
              <nuxt-link class="text-primary" :to="`/admin/lookup-values/${lookupValue._id}`">{{ lookupValue.description }}</nuxt-link>
            </td>
            <td class="text-no-wrap">{{ lookupValue.organization?.description }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
const props = defineProps<{ lookupTypeId: string }>()
const { lookupTypeId } = toRefs(props)

const lookupValueStore = useLookupValueStore()

const {
  data: lookupValues,
  pending,
  refresh,
} = useLazyAsyncData(
  `lookup-values-by-lookuptype-${lookupTypeId.value}`,
  async () => {
    const populate = [
      {
        path: "organization",
        model: "Organization",
        select: ["description"],
      },
      {
        path: "parent",
        model: "LookupValue",
        select: ["description"],
      },
    ]

    const result = await lookupValueStore.getLookupValuesByPage({ pagination: false, query: { lookupType: lookupTypeId.value }, populate })
    const docs = result.docs as unknown as Array<
      LookupValue & { organization?: { _id: string; description: string } } & { parent?: { _id: string; description: string } }
    >

    // First sort ascending on parent.description, then ascending on description, leaving empty parent.description values at the end
    docs.sort((a, b) => {
      if (a.parent?.description && b.parent?.description) {
        return a.parent.description.localeCompare(b.parent.description) || a.description.localeCompare(b.description)
      } else if (a.parent?.description) {
        return -1
      } else if (b.parent?.description) {
        return 1
      } else {
        return a.description.localeCompare(b.description)
      }
    })

    return docs
  },
  {
    default: () =>
      [] as Array<
        LookupValue & { organization?: { _id: string; description: string } } & { parent?: { _id: string; description: string } }
      >,
  },
)
</script>
